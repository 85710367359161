// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadString } from 'firebase/storage'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import xlsx from "json-as-xlsx";


class Firebase {

    constructor() {

        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: "AIzaSyBZfTOtr61IpRyLvF5a0srH2iIRqdVW0F0",
            authDomain: "city-playground.firebaseapp.com",
            projectId: "city-playground",
            storageBucket: "city-playground.appspot.com",
            messagingSenderId: "990637524919",
            appId: "1:990637524919:web:65e1a6eaca64a57c138a32"
        };

        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app)
        this.db = getFirestore(this.app);
        this.storage = getStorage(this.app, 'gs://city-playground.appspot.com');
        this.imgStorage = ref(this.storage, 'images')
        this.jsonStorage = ref(this.storage, 'json')
        this.excelStorage = ref(this.storage, 'excel')
        this.json = ref(this.jsonStorage, 'city-playground.json')
        this.createExcel = this.createExcel.bind(this)
        this.getDocs = this.getDocs.bind(this)

        // this.createExcel = this.createExcel.bind(this)
    }

    init(){
        // if(window.location.pathname = '/download'){
            document.querySelector('.excel-button').addEventListener('click', async () => {
                this.createExcel(JSON.stringify(await this.getDocs()))
                setTimeout(() => {
                    localStorage.setItem('auth', false)
                }, 600000)
            })
        // }
    }

    async getCollectionLength() {
        var length = 0

        try {
            const querySnapshot = await getDocs(collection(this.db, "saved_images"));
            querySnapshot.forEach(() => {
                length++
            })

            return length

        } catch (error) {

            console.log(error)
        }
    }

    async getDocs() {
        var object = []
        const querySnapshot = await getDocs(collection(this.db, "saved_images"));
        querySnapshot.forEach((value) => {
            object.push(value._document.data.value.mapValue.fields)
        })
        console.log(object)
        return object
    }

    async addDoc(data) {

        try {
            await addDoc(collection(this.db, "saved_images"), data);
        }
        catch (error) {
            console.log(error)
        }
    }

    addRecord(name, nickName, file) {
        var imgRef = ref(this.imgStorage, name)
        uploadString(imgRef, file, 'data_url').then(() => {
            this.getImgUrl(name, nickName);
            return this.updateJson()
        }).catch((error) => {
            console.log(error)
        })
    }

    getImgUrl(name, nickName) {
        var imgRef = ref(this.imgStorage, name)
        getDownloadURL(imgRef)
            .then((url) => {
                console.log(url)
                this.addDoc({
                    user_nickname: nickName,
                    image_url: url.toString(),
                    img_name: name
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    async updateJson() {

        var json = JSON.stringify(await this.getDocs())
        var jsonRef = ref(this.jsonStorage, 'city-playgound.json')

        uploadString(jsonRef, json).then((snapshot) => {
            console.log(snapshot)
            // return this.createExcel(JSON.parse(json))
            // this.uploadExcel(JSON.parse(json))

            json = JSON.parse(json)
            console.log(json)

            var data = [
                ['Nickname', 'Nome immagine', 'Url immagine'],
            ]



            Array.from(json).map((user) => {
                data.push([(user.image_url) ? user.image_url.stringValue: null,
                           (user.image_name) ? user.image_name.stringValue : null, 
                           (user.user_nickname) ? user.user_nickname.stringValue : null
                        ])
            })

            let excelData = '';
            console.log(data)

            // Prepare data for excel.You can also use html tag for create table for excel.
            data.forEach((rowItem, rowIndex) => {

                if (0 === rowIndex) {
                    // This is for header.
                    rowItem.forEach((colItem, colIndex) => {
                        excelData += colItem + ',';
                    });
                    excelData += "\r\n";

                } else {
                    // This is data.
                    rowItem.forEach((colItem, colIndex) => {
                        excelData += colItem + ',';
                    })
                    excelData += "\r\n";
                }
            })

            this.uploadExcel(excelData)

            // document.querySelector('.excel-download').addEventListener('click', function(){
            //     this.createExcel(json)
            // })


        }).catch((error) => {
            console.log(error)
        })
    }

    createExcel(json) {

        json = JSON.parse(json)
        console.log(json)

        let settings = {
            fileName: "City Playground Export",
            extraLength: 10,
            writeMode: "writeFile"
        }

        var data = [
            {
                sheet: "Users",
                columns: [
                    { label: "Nickname", value: "user_nickname" },
                    { label: "Nome Immagine", value: "image_name" },
                    { label: "Url Immagine", value: "image_url" },
                ],
                content: []
            }
        ]



        Array.from(json).map((user) => {
            data[0].content.push({
                'image_url': (user.image_url) ? user.image_url.stringValue : null,
                'image_name': (user.image_name) ? user.image_name.stringValue : null,
                'user_nickname': (user.user_nickname) ? user.user_nickname.stringValue : null,
            })
        })

        console.log(data)

        xlsx(data, settings)
    }

    async uploadExcel(data) {
        var excelData = "data:text/xlsx," + encodeURI(data)
        var excelRef = ref(this.excelStorage, 'city-playground.xlsx')
        uploadString(excelRef, excelData, 'data_url').then((snapshot) => {
            console.log(snapshot)
            // let a = document.querySelector(".excel-button");
            // a.setAttribute("href", excelData);
            // a.setAttribute("download", "city-playground.xlsx");

        }).catch((error) => {
            console.log(error)
        })
    }

    login(email, password){
        signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            localStorage.setItem('auth', true)
            window.location.href = window.location.origin + '/download.html'
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        });
    }

}

export default new Firebase();