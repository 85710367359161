export default class Menu {
    constructor(header) {
        this.node = header
        this.menu = this.node.querySelector('.menu')
        this.button = this.node.querySelector('.hamburger')

        this.clickOpenClose = this.clickOpenClose.bind(this)

        this.init()
    }

    init() {
        console.debug('  -> Menu init')
        this.reset()
        this.addListeners()
    }

    addListeners() {
        this.button.addEventListener('click', this.clickOpenClose)
    }

    clickOpenClose(e) {
        e.preventDefault()

        if (this.menu.classList.contains('open')) {
            console.log('open')
            this.menu.classList.remove('open')
            this.menu.classList.add('close')
            this.button.classList.remove('open')
            this.button.classList.add('close')
        } else {
            this.menu.classList.add('open')
            this.menu.classList.remove('close')
            this.button.classList.add('open')
            this.button.classList.remove('close')
            document.querySelector('body').classList.add('overflow-hidden')
        }
    }

    clickToggleSubmenu(e) {
        // e.preventDefault()
        e.target.parentElement.classList.toggle('show')
    }

    reset() {
        console.debug('  -> Menu reset')

        this.node.classList.remove('open')
    }

    static destroy() {
        console.debug('  -> Menu destroy')

        this.reset()

        this.buttonBurger.removeEventListener('click', this.clickOpen)
        this.buttonClose.removeEventListener('click', this.clickClose)
    }

    static init() {
        console.debug('== Menu component ==')
        Menu.item = new Menu(document.querySelector('header'))
    }
}
