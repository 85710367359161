import html2canvas from 'html2canvas';
import Firebase from './Firebase';

export default class ScreenShot {
    constructor(cube) {
        this.cube = cube

        this.button = document.querySelector('.save-button')
        this.confirmButton = document.querySelector('.confirm-button')
        this.nickName = document.querySelector('#nick-name')

        this.confirm = this.confirm.bind(this)
        this.shoot = this.shoot.bind(this)

        this.init()
    }

    init() {
        // console.debug('  -> Screenshot init')
        this.reset()
        this.addListeners()
    }

    addListeners() {
        if(this.button.classList.contains('active')){
            this.button.addEventListener('click', this.shoot)
        }
        
        this.confirmButton.addEventListener('click', this.confirm)
    }

    async confirm(){

        html2canvas(this.cube).then(canvas => {
            document.body.appendChild(canvas)
        });

        document.querySelector('.fase--3').style.display = 'none'
        document.querySelector('.fase--4').style.display = 'block'

        var length = await Firebase.getCollectionLength()
        var nickName = this.nickName.value

        setTimeout(() => {
            var name = (nickName !== 'undefined' && nickName !== "") ? `city-playground-${nickName}.png` : `city-playground-${length}.png`;
            var json = Firebase.addRecord(name, nickName, document.querySelector('canvas').toDataURL());
            
            console.log(encodeURI(json))
        }, 1000)
    }

    async shoot() {

        setTimeout(() => {
            var link = document.querySelector('#canvas-link')
            link.download = 'city-playground.png'
            link.href = document.querySelector('canvas').toDataURL()
            link.click()
        }, 1000)

        this.button.classList.remove('active')
    }

    reset() {
        console.debug('  -> Menu reset')
    }

    static destroy() {
        console.debug('  -> Menu destroy')

        this.reset()
    }

    static init() {
        console.debug('== Menu component ==')
        ScreenShot.item = new ScreenShot(document.querySelector('.section--grid'))
    }
}
