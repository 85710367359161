export default class Share {

    constructor(button) {
        this.button = button
        this.url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href)

        this.shareFacebook = this.shareFacebook.bind(this)

        this.init()
    }

    init(){
        this.addListeners()
    }

    addListeners() {
        this.button.addEventListener('click', this.shareFacebook)
    }

    shareFacebook() {
        this.openSharingWindow(this.url)
        console.log(this.url)
    }

    openSharingWindow(url) {
        const width = 500
        const height = 400
        const left = window.screen.width / 2 - width / 2
        const top = window.screen.height / 2 - height / 2

        const features = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`

        window.open(url, 'socialSharing', features)
    }

    static init() {
        console.debug('== Sliders components ==')
        Share.items = Array.from(document.querySelectorAll('.share-button')).map(
            (button) => new Share(button)
        )
    }

}
