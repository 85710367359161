export default class Loader{

    constructor(loader){
        this.loader = loader

        this.fade = this.fade.bind(this)
        this.init()
    }

    init(){
        this.addListeners()
    }

    addListeners(){
        document.addEventListener('DOMContentLoaded', this.fade)
    }

    fade(){
        document.querySelector('html').style.overflow = 'hidden'
        setTimeout(function(){
            document.querySelector('.section--loader').classList.add('fade')
        }, 1000)
        setTimeout(function(){
            document.querySelector('.section--loader').style.display = 'none'
            document.querySelector('html').style.overflow = 'initial'
        }, 2200)
    }

    static init(){
        Loader.items = Array.from(document.querySelectorAll('.section--loader')).map(
            (loader) => new Loader(loader)
        )
    }
}