import App from './modules/App'
import Menu from './modules/Menu'
import Sliders from './modules/Slider'
import ScreenShot from './modules/Screenshot'
import Share from './modules/Share'
import Loader from './modules/Loader'
import Firebase from './modules/Firebase'
import Login from './modules/Login'


// Debug helper
const debug = true

if (!debug) {
  console.log = function () {}
}

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    console.log(window.location.pathname)

    if(window.location.pathname == '/'){
      localStorage.setItem('auth', false)

      App.init()
      Loader.init()
      Menu.init()
      ScreenShot.init()
      Share.init()
      Sliders.init()

    }else if(window.location.pathname == '/download.html'){
      
      if(localStorage.getItem('auth')){

        Firebase.init()

      }else{

        window.location.href = window.location.origin + '/login.html'
      }

    }else if(window.location.pathname == '/login.html'){
      
      Login.init()
    }
  }
}

const main = new Main()
