import Firebase from "./Firebase"

class Login {
    
    constructor(){

        this.submit = document.querySelector('.login-submit')
        this.username = document.querySelector('.username')
        this.password = document.querySelector('.password')

        this.login = this.login.bind(this)

        // this.init()
    }

    init(){
        this.addListeners()
    }

    addListeners(){
        this.submit.addEventListener('click', this.login)
    }

    login(){
        Firebase.login(this.username.value, this.password.value)
    }
}

export default new Login()