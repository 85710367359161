// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { EffectCoverflow, Navigation } from 'swiper/modules'

// gsap.registerPlugin(ScrollTrigger)

export default class Sliders {
    constructor(slider, index) {
        this.slider = slider
        this.index = index

        this.options = this.setOptions()
        this.initSlider()
    }

    setOptions() {

        var coverflowOptions = (window.innerWidth <= 768) ? 
        {
            rotate: 0,
            stretch: 240,
            depth: 50,
            modifier: 1,
            scale: 0.6,
            slideShadows: false,   
        } : {
            rotate: 0,
            stretch: 250,
            depth: 50,
            modifier: 1,
            scale: 0.6,
            slideShadows: false,
        }

        let options = {
            modules: [EffectCoverflow, Navigation],
            effect: "coverflow",
            grabCursor: true,
            centeredSlides: true,
            activeIndex: 2,
            loop: true,
            slidesPerView: "auto",
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            coverflowEffect: coverflowOptions,
        }

        return options
    }

    initSlider() {
        console.debug('  -> Sliders init')
        let swiperInstance = new Swiper(this.slider, this.options)

        swiperInstance.init()
        this.swiperInstance = swiperInstance

        this.loadedCheck()

        // window.addEventListener('resize', () => {
        //   let viewportHeight = window.innerHeight
        //   let viewportWidth = window.innerWidth

        //   if (this.slider.classList.contains('swiper--home')) {
        //     if (viewportWidth <= 768) {
        //       swiperInstance = new Swiper(this.slider, this.options)
        //     } else if (viewportWidth > 768) {
        //       this.destroy()
        //     }
        //     this.swiperInstance = swiperInstance
        //   }
        // })
    }



    loadedCheck() {
        let viewportHeight = window.innerHeight
        let viewportWidth = window.innerWidth

        // if (this.slider.classList.contains('swiper--home')) {
        //   if (viewportWidth > 768) {
        //     this.destroy()
        //   }
        // }
    }

    destroy() {
        console.debug('  -> Sliders destroy')
        this.swiperInstance.destroy()
    }

    static destroyAll() {
        console.debug('  -> Sliders destroy all')
        Sliders.items.forEach((slider) => {
            slider.destroy()
        })
    }

    static init() {
        console.debug('== Sliders components ==')
        Sliders.items = Array.from(document.querySelectorAll('.swiper')).map(
            (slider, index) => new Sliders(slider, index)
        )
    }
}

// function slideHeroAnimate(slide) {
//   const label = slide.querySelector('.label')
//   const title = slide.querySelector('.title')
//   const text = slide.querySelector('.text')
//   const link = slide.querySelector('.link')

//   const tlSlideHero = gsap.timeline()
//   tlSlideHero
//     .from(label, {
//       autoAlpha: 0,
//       delay: 0.25,
//       duration: 1,
//       x: -20,
//       ease: 'expo.out',
//     })
//     .from(title, {
//       autoAlpha: 0,
//       delay: -0.75,
//       duration: 1,
//       x: -20,
//       ease: 'expo.out',
//     })
//     .from(text, {
//       autoAlpha: 0,
//       delay: -0.75,
//       duration: 1,
//       x: -20,
//       ease: 'expo.out',
//     })
//     .from(link, {
//       autoAlpha: 0,
//       delay: -0.75,
//       duration: 0.9,
//       x: -20,
//       ease: 'expo.out',
//     })
// }
