// import Swiper from "swiper"

export default class App {

    constructor(app, index){
        this.app = app
        this.cubes = document.querySelectorAll('.cube')
        this.index = index
        this.slides = document.querySelectorAll('.swiper-slide')
        this.images = []
        this.cubeNr = 5
        this.activeSlide = document.querySelector('.swiper-slide-active')
        this.button = document.querySelector('.carousel-button')
        this.backButton = document.querySelector('.back-button')
        this.resetButton = document.querySelector('.reset-button')
        this.logoButton = document.querySelector('.title-logo')
        this.creditsButton = document.querySelector('.menu-item--credits')

        this.changeCubeNr = this.changeCubeNr.bind(this)
        this.changePage1 = this.changePage1.bind(this)
        this.changePage2 = this.changePage2.bind(this)
        this.changePage3 = this.changePage3.bind(this)
        this.changePageHome = this.changePageHome.bind(this)
        this.fillCarousel = this.fillCarousel.bind(this)
        this.fillCube = this.fillCube.bind(this)
        this.reset = this.reset.bind(this)
        this.init()
    }
    

    init(){
        this.addListeners()
        let swiper = document.querySelector('.swiper')
        setTimeout(() => {
            swiper.swiper.slideNext(100)
        }, 200)
    }

    addListeners(){
        var cubeNr

        Array.from(this.cubes).forEach((cube, index) => {
            cube.addEventListener('click', () => {
                if(cube.classList.contains('active')){
                    this.fillCarousel(cube)
                    this.app.classList.add('no-interaction')
                    cubeNr = index + 1
                }
            })
        })
        
        Array.from(this.slides).map((slide) => {
            if(slide.classList.contains('swiper-slide-active')){
                slide.addEventListener('click', () => {
                    this.fillCube(cubeNr)
                })
            }
        })

        this.button.addEventListener('click', () => {
            this.fillCube(cubeNr)
        })

        this.resetButton.addEventListener('click', this.reset)
        this.backButton.addEventListener('click', this.changePage2)
        this.creditsButton.addEventListener('click', this.changePage3)
        this.logoButton.addEventListener('click', this.changePageHome)
    }

    changePage1(){
        this.app.classList.add('carousel-view')
    }

    changePage2(){
        this.app.classList.remove('carousel-view')
    }

    changePageHome(){
        this.app.classList.remove('carousel-view')
        this.app.classList.remove('credits')
    }

    changePage3(){
        this.app.classList.add('credits')
        document.querySelector('.menu').classList.remove('open')
        document.querySelector('.hamburger').classList.remove('open')
    }

    changeCubeNr(cube){
        return cube.getAttribute('data-cube')
    }

    fillCarousel(cube){
        var cubes = Array.from(this.cubes)
        var selectedCube = cubes[this.changeCubeNr(cube) - 1]
        var slides = Array.from(this.slides)
        var question = selectedCube.querySelector('.face--1').innerHTML
        let swiper = document.querySelector('.swiper')
        let time = 1000

        Array.from(selectedCube.querySelectorAll('.face')).map((face, index) => {

            if(index > 0){
                slides[index - 1].setAttribute('data-face', face.getAttribute('data-face'))
                slides[index - 1].querySelector('img').src = face.querySelector('img').src
                slides[index - 1].querySelector('.jpg').srcset = face.querySelector('.jpg').srcset
                slides[index - 1].querySelector('.big-jpg').srcset = face.querySelector('.big-jpg').srcset
                slides[index - 1].querySelector('.webp').srcset = face.querySelector('.webp').srcset
                slides[index - 1].querySelector('.big-webp').srcset = face.querySelector('.big-webp').srcset
                slides[index - 1].querySelector('.label').innerHTML = face.querySelector('.label').innerHTML
            }  
        })

        // ANIMATIONS

        setTimeout(function(){
            swiper.classList.add('effect')
        }, 600)
        setTimeout(function(){
            swiper.classList.remove('effect')
        }, 3400)
        setTimeout(function(){
            document.querySelector('.app-container').classList.remove('no-interaction')
        }, 3900)

        for(var i = 0; i < 5; i++){
            time += (1 * (400 * (i + 1))) - (400 * i)
            setTimeout(function(){
                swiper.swiper.slideNext(200)
            }, time)
            console.log(time)
        }

        document.querySelector('.question').innerHTML = question   

        this.changePage1()

        return this.changeCubeNr(cube)
    }

    fillCube(cubeNr){

        var selectedSlide = document.querySelector('.swiper-slide-active')
        var selectedCube = this.cubes[cubeNr - 1]
        var faceNr = selectedSlide.getAttribute('data-face')
        var jpg = selectedSlide.querySelector('img').src
        var bigJpg = selectedSlide.querySelector('.big-jpg').srcset
        var webp = selectedSlide.querySelector('.webp').srcset
        var bigWebp = selectedSlide.querySelector('.big-webp').srcset
        var label = selectedSlide.querySelector('.label').innerHTML
        var faces = selectedCube.querySelectorAll('.face')

        Array.from(faces).map((face) => {
            face.classList.remove('show')

            if(face.getAttribute('data-face') == parseInt(faceNr)){
                face.classList.add('show')
                face.querySelector('img').src = jpg
                face.querySelector('.jpg').src = jpg
                face.querySelector('.big-jpg').src = bigJpg
                face.querySelector('.webp').src = webp
                face.querySelector('.big-webp').src = bigWebp
                face.querySelector('.label').innerHTML = label
            }
        })

        if(cubeNr < 9){
            if(cubeNr == 5){

                this.cubes[0].classList.add('active')
            }if(cubeNr < 4 || cubeNr > 5){

                this.cubes[cubeNr].classList.add('active')
            }else if(cubeNr == 4){

                this.cubes[cubeNr + 1].classList.add('active')
            }
        }

        
        var completedCubes = document.querySelectorAll('.face.show').length

        if(completedCubes == 0){

            document.querySelector('.fase--2').style.display = 'none'
            document.querySelector('.fase--3').style.display = 'none'

        }else if(completedCubes < 9){

            document.querySelector('.fase--1').style.display = 'none'
            document.querySelector('.fase--2').style.display = 'block'
            document.querySelector('.fase--3').style.display = 'none'

        }else if(completedCubes == 9){

            document.querySelector('.fase--1').style.display = 'none'
            document.querySelector('.fase--2').style.display = 'none'
            document.querySelector('.fase--3').style.display = 'block'
            
        }

        document.querySelector('#to-complete').innerHTML = 9 - completedCubes

        this.changePage2()       
    }

    reset(){
        var faces = document.querySelectorAll('.face')

        Array.from(this.cubes).map((cube, index) => {
            cube.classList.remove('active')
            if(index == 4){
                cube.classList.add('active')
            }
        })

        Array.from(faces).map((face) => {
            face.classList.remove('show')
        })

        document.querySelector('.fase--1').style.display = 'block'
        document.querySelector('.fase--2').style.display = 'none'
        document.querySelector('.fase--3').style.display = 'none'
        document.querySelector('.share-button').classList.add('active')
    }
    
    static init() {
        App.items = Array.from(document.querySelectorAll('.app-container')).map(
            (app, index) => new App(app, index)
        )
    }
}